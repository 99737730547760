import React, { Component } from 'react'
import NewLayout from '../layout/newLayout'
import SEO from "../components/seo"
import Title from '../components/title';
import styles from '../styles/scss/pages/stormRecovery.module.scss'
import cn from "classnames"
import Imgix from 'react-imgix';

export default class StormRecovery extends Component {
  render() {
    const pageData = this.props.pageContext.page;
    return (
      <NewLayout>
        <SEO page={pageData} />
        <div className={cn(styles.stormRecovery, 'd-flex flex-column')}>
          <Imgix src="/img/storm-recovery/hero_photo.png" className={cn(styles.hero)} htmlAttributes={{alt: ''}} />
          <div className={styles.pageContent}>
            <div className={styles.firstSection}>
              <Title value="Be aware. Be Prepared. Be Safe." size="30px" />
              <p>
                Hurricanes can be one of the most potentially devastating weather events a community can experience. Powerful winds, heavy rainfall and flooding can cause large-scale damage to businesses, homes and roads. We at WD-40 Company know that protecting your family and home is a top priority.
              </p>
              <p className={styles.marginTop}>
                WD-40 Company first supported hurricane recovery efforts in 1961, when employees came in after hours to produce additional concentrate to meet the disaster needs of the victims of Hurricane Carla along the U.S. Gulf coast. WD-40® Multi-Use Product was used to recondition flood- and rain-damaged vehicles and equipment. Today, many households already have a blue and yellow can in their home, so the ability of WD-40 Brand products to help is even more accessible. That's why we've compiled a list of the most important tips from government agencies and the American Red Cross for pre- and post-storm consideration. Be sure to listen to local radio, television or NOAA radio for weather updates.
              </p>
            </div>
            <div className={cn(styles.secondSection, 'd-flex')}>
              <div className={styles.imageContainer}>
                <Imgix src="/img/storm-recovery/icon01.png" htmlAttributes={{alt: ''}} />
              </div>
              <div className={cn(styles.textContainer, 'd-flex flex-column')}>
                <Title value="Protect your family:" size="30px" />
                <div className={cn(styles.content, 'd-flex flex-column')}>
                  <ul>
                    <li>
                      Create a household evacuation plan and discuss where you will go should you be evacuated. Options include checking into a hotel, staying with friends or family in a safe area, or going to an American Red Cross shelter.
                    </li>
                    <li style={{ marginTop: '20px' }}>
                      Assemble a family emergency kit, including:
                      <ul>
                        <li>
                          Water, food, medications, personal hygiene items, a first aid kit, emergency blankets and extra cash.
                        </li>
                        <li>
                          Copies of personal documents, such as passports, birth certificates, insurance cards, emergency contact information and maps.
                        </li>
                        <li>
                          Battery-powered or hand-crank radio, cell phones with chargers and extra house and car keys.
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      Ensure your family is prepared with critical items like flashlights and radios by cleaning away dirt, oil and condensation from sensitive electronics with WD-40® Specialist® Electrical Contact Cleaner Spray.
                    </li>
                    <li>
                      Review home insurance policies to ensure proper coverage and take photos of property.
                    </li>
                    <li>
                      Fill your car’s gas tank to full and stock your vehicle with emergency supplies and a change of clothes.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className={cn(styles.firstSection, 'd-flex')}>
              <div className={styles.imageContainer}>
                <Imgix src="/img/storm-recovery/icon02.png" htmlAttributes={{alt: ''}} />
              </div>
              <div className={cn(styles.textContainer, 'd-flex flex-column')}>
                <Title value="PREPARE YOUR PROPERTY:" size="30px" />
                <div className={cn(styles.content, 'd-flex flex-column')}>
                  <ul>
                    <li>
                      Secure all windows, doors and loose outside items.
                    </li>
                    <li>
                      Declutter all gutters and drains and install valves in plumbing to prevent backups.
                    </li>
                    <li>
                      Caulk around doors and windows to prevent wind-driven rain from causing moisture damage in your home.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className={cn(styles.firstSection, 'd-flex')}>
              <div className={styles.imageContainer}>
                <Imgix src="/img/storm-recovery/icon03.png"  htmlAttributes={{alt: ''}} />
              </div>
              <div className={cn(styles.textContainer, 'd-flex flex-column')}>
                <Title value="DURING THE STORM:" size="30px" />
                <div className={cn(styles.content, 'd-flex flex-column')}>
                  <ul>
                    <li>
                      If told to evacuate, do so immediately.
                    </li>
                    <li>
                      If sheltering in your home, seek out a small, windowless interior room on the lowest floor that is not subject to flooding.
                    </li>
                    <li>
                      Only use a generator or other gasoline-powered machinery outdoors.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className={cn(styles.firstSection, 'd-flex')}>
              <div className={styles.imageContainer}>
                <Imgix src="/img/storm-recovery/icon04.png" htmlAttributes={{alt: ''}} />
              </div>
              <div className={cn(styles.textContainer, 'd-flex flex-column')}>
                <Title value="POST STORM CLEANUP:" size="30px" />
                <div className={cn(styles.content, 'd-flex flex-column')}>
                  <ul>
                    <li>
                      Wear protective gloves and clothing to protect yourself from broken glass, splintered wood and debris.
                    </li>
                    <li>
                      Avoid wading in flood water, which can contain dangerous debris. Underground or downed power lines can also electrically charge the water.
                    </li>
                    <li>
                      Use WD-40® Multi-Use Product to drive out moisture in your flooded belongings, such as equipment with electrical connections and car engines:
                      <ul>
                        <li>
                          Electric generators and power panel boxes (power must be off)
                        </li>
                        <li>
                          Electrical contacts (power must be off)
                        </li>
                        <li>
                          Flooded engines (on a cold engine away from any heat sources)
                        </li>
                      </ul>
                    </li>
                    <li>
                      Throw out items that have absorbed water and cannot be cleaned or disinfected, including mattresses, carpeting, cosmetics, stuffed animals and baby toys.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className={cn(styles.firstSection, 'd-flex')}>
              <div className={cn(styles.textContainer, 'd-flex flex-column')}>
                <Title value="OTHER RESOURCES:" size="30px" />
                <a target="_blank" rel="noopener noreferrer" href="https://www.redcross.org/get-help/how-to-prepare-for-emergencies/types-of-emergencies/hurricane.html">American Red Cross</a>
                <a target="_blank" rel="noopener noreferrer" href="https://www.fema.gov/media-library-data/1494007144395-b0e215ae1ba6ac1b556f084e190e5862/FEMA_2017_Hurricane_HTP_FINAL.pdf">FEMA Hurricane Preparedness</a>
                <a target="_blank" rel="noopener noreferrer" href="https://www.noaa.gov/">NOAA</a>
                <a target="_blank" rel="noopener noreferrer" href="https://www.ready.gov/plan">Ready.gov</a>
              </div>
            </div>
          </div>
        </div>
      </NewLayout>
    );
  }
}